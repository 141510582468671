/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import './Kyb.css'
import Sidebar from '../SideBar/Sidebar'
// import PdfViewer from 'pdf-viewer-reactjs';
import { Box, Grid, Button, useTheme, useMediaQuery, styled, Snackbar, Tabs, Tab, Typography } from '@mui/material';
// import Grid from '@mui/material/Unstable_Grid';
// import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Backdrop from '@mui/material/Backdrop';
import Axios from '../../../Axios';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../../../middleware';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PDFViewer from './PDFViewer';

function Kyb() {

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const [imageUrl, setImageUrl] = useState(null);
    const [load, setLoad] = useState("")

    const [load2, setLoad2] = useState(false)

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const [usertype, setUserType] = useState('')
    const token = localStorage.getItem('Rikosta')

    const subAdminDetails = JSON.parse(localStorage.getItem('subadmin'))


    useEffect(() => {
        if (token) {
            var user = window.localStorage.getItem('userType');
            const userType = decryptData(user)
            setUserType(userType)
        }
    }, [token])

    const [companyName, setCompanyName] = useState("")
    const [dateOfReg, setDateOfReg] = useState("")
    const [companyEmail, setCompanyEmail] = useState("")
    const [companyPhone, setCompanyPhone] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyAddressImg, setCompanyAddressImg] = useState("")
    const [vatNumber, setVatNumber] = useState("")
    const [vatImg, setVatImg] = useState("")
    const [itNumber, setItNumber] = useState("")
    const [itImg, setItImg] = useState("")
    const [bankImg, setBankImg] = useState("")
    const [direcorImg, setDirecorImg] = useState([])

    const [directorsApprove, setDirectorsApprove] = useState('');
    const [addrApprove, setAddrApprove] = useState('');
    const [vatApprove, setVatApprove] = useState('');
    const [incomeApprove, setIncomeApprove] = useState('');
    const [bankApprove, setBankApprove] = useState('');

    const [DirectorsReason, setDirectorsReason] = useState('');
    const [AddressReason, setAddressReason] = useState('');
    const [VatReason, setVatReason] = useState('');
    const [IncomeReason, setIncomeReason] = useState('');
    const [BankReason, setBankReason] = useState('');
    const [id_verify, setId_verify] = useState()
    const [forpdf, setForpdf] = useState(false)
    const [kycStatus, setKycStatus] = useState(false)

    const [statusKyc, setStatusKyc] = useState("")
    const [kybDetails, setKybDetails] = useState()
    // console.log(kybDetails, 'kybDetails');


    const [resons, setReason] = useState("")



    const clearData = async () => {
        try {
            setCompanyName("")
            setDateOfReg("")
            setCompanyEmail("")
            setCompanyPhone("")
            setCompanyAddress("")
            setCompanyAddressImg("")
            setVatNumber("")
            setVatImg("")
            setItNumber("")
            setItImg("")
            setBankImg("")
            setDirecorImg([])
            setKycStatus(false)
        } catch (error) {
            console.log("🚀 ~ clearData ~ error:", error)
        }
    }

    const [apiCallStatus, setApiCallStatus] = useState(false)

    const checkkyb = async () => {
        try {
            setApiCallStatus(true)
            const { data } = await Axios.get('/checkkyb', {
                headers: {
                    Authorization: window.localStorage.getItem('Rikosta')
                }
            })
            if (data?.success == true) {
                // PDFViewer(companyAddressImg)
                console.log("🚀 ~ checkkyb ~ data:", data?.result)
                setKybDetails(data.result)
                setStatusKyc(data?.result?.Verify)
                setReason(data?.result?.reason)
                setKycStatus(true)
                setCompanyName(data?.result?.CompanyName)
                setDateOfReg(data?.result?.DateOfReg)
                setCompanyEmail(data?.result?.CompanyEmail)
                setCompanyPhone(data?.result?.Phone)
                setCompanyAddress(data?.result?.Address)
                setVatNumber(data?.result?.VatNumber)
                setVatImg(data?.result?.VatNumber_Proof)
                setItNumber(data?.result?.ITNumber)
                setDirecorImg(data?.result?.Diretors)
                //verification details
                setAddrApprove(data?.result?.address_reject)
                setVatApprove(data?.result?.vat_reject)
                setIncomeApprove(data?.result?.incometax_reject)
                setBankApprove(data?.result?.bank_reject)
                setDirectorsApprove(data?.result?.director_reject)
                setId_verify(data?.result?.document_id_verify)
                setAddressReason(data?.result?.address_reject_reason)
                setVatReason(data?.result?.vat_reject_reason)
                setIncomeReason(data?.result?.incometax_reject_reason)
                setBankReason(data?.result?.bank_reject_reason)
                setDirectorsReason(data?.result?.director_reject_reason)
                setApiCallStatus(false)
                let add = data?.result?.Address_Proof.split(".")
                let Vat = data?.result?.VatNumber_Proof.split(".")
                let It = data?.result?.ITNumber_Proof.split(".")
                let Bank = data?.result?.Bank_Proof.split(".")
                // console.log(data,"lakshmi");
                console.log(data?.result?.ITNumber_Proof, It[3], "Vijay");
                if (add[3] === "pdf" || Vat[3] === "pdf" || It[3] === "pdf" || Bank[3] === "pdf") {
                    console.log(add[3], "add[1]");
                    setForpdf(true)
                    setCompanyAddressImg(data?.result?.Address_Proof)
                    setVatImg(data?.result?.VatNumber_Proof)
                    setItImg(data?.result?.ITNumber_Proof)
                    setBankImg(data?.result?.Bank_Proof)
                }
                else if (add[3] != "pdf" || Vat[3] != "pdf" || It[3] != "pdf" || Bank[3] != "pdf") {
                    console.log(add[3], "add[1]false");
                    setForpdf(false)
                    setCompanyAddressImg(data?.result?.Address_Proof)
                    setVatImg(data?.result?.VatNumber_Proof)
                    setItImg(data?.result?.ITNumber_Proof)
                    setBankImg(data?.result?.Bank_Proof)
                }

            } else {
                setApiCallStatus(false)
                // toast.error("Please Verify Your KYB")
                // navigate('/kyb')
            }
        } catch (error) {
            setApiCallStatus(false)
            console.log("🚀 ~ checkkyb ~ error:", error)
        }
    }

    useEffect(() => {
        console.log(itImg, bankImg, "bankimage");

        checkkyb()
    }, [])

    const imageUpload = async (imgForm, type) => {
        try {
            setLoad(type)
            const formdata = new FormData();
            formdata.append("image", imgForm)
            const { data } = await Axios.post('/uploadimage', formdata, {
                headers: {
                    Authorization: window.localStorage.getItem('Rikosta')
                }
            })
            if (data?.success) {
                setLoad("")
                return data?.result
            }
            setLoad("")
        } catch (error) {
            setLoad("")
            console.log("🚀 ~ imageUpload ~ error:", error)
        }
    }

    const handleImageUpload = async (event, type) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            console.log(file, "file");

            const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf'];
            if (!validImageTypes.includes(file.type)) {
                toast.error('Please select a valid image file (jpg, png, jpeg, webp).');
                return;
            } else {
                setForpdf(false)
                var img = await imageUpload(file, type)
                if (img) {
                    if (type == "address") {
                        if (file.type === 'application/pdf') {
                            setForpdf(true)
                            setCompanyAddressImg(img)
                        } else {
                            setForpdf(false)
                            setCompanyAddressImg(img)
                        }
                    } else if (type == "vat") {
                        if (file.type === 'application/pdf') {
                            setForpdf(true)
                            setVatImg(img)
                        } else {
                            setForpdf(false)
                            setVatImg(img)
                        }

                    } else if (type == "it") {
                        if (file.type === 'application/pdf') {
                            setForpdf(true)
                            setItImg(img)
                        } else {
                            setForpdf(false)
                            setItImg(img)
                        }

                    } else if (type == "bank") {
                        if (file.type === 'application/pdf') {
                            setForpdf(true)
                            setBankImg(img)
                        } else {
                            setForpdf(false)
                            setBankImg(img)
                        }

                    }
                }
                reader.readAsDataURL(file);
                // setLoad(true)
                // const time = setTimeout(() => {
                //     setLoad(false)
                // }, 3000)

                // return () => {
                //     clearTimeout(time)
                // }


            }

        }


    };

    const navigate = useNavigate()

    const [imageUrls, setImageUrls] = useState([]); // Store multiple images in an array
    //   const [load, setLoad] = useState(false);

    const handleImageUpload2 = async (event) => {
        const files = event.target.files; // This will give you all selected files
        const fileArray = Array.from(files); // Convert file list to array
        const readers = [];
        fileArray.forEach(async (file) => {
            const reader = new FileReader();
            readers.push(reader);
            reader.onloadend = () => {
                setImageUrls((prevUrls) => [...prevUrls, reader.result]); // Append each image URL to the array
            };
            if (file) {
                const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
                if (!validImageTypes.includes(file.type)) {
                    toast.error('Please select a valid image file (jpg, png, jpeg, webp).');
                    return;
                } else {
                    reader.readAsDataURL(file);
                    var img = await imageUpload(file, "director")
                    setDirecorImg([...direcorImg, img])
                    // const time = setTimeout(() => {
                    //     setLoad2(false);
                    // }, 3000);
                    // return () => {
                    //     clearTimeout(time);
                    // };
                }
            }
        });
    };
    const [apiStauts, setApiStatus] = useState(false)

    const handleSave = async () => {
        // setOpen(true)
        // const timer = setTimeout(() => {
        //     setOpen(false)
        // }, 5000)
        // return () => clearTimeout(timer)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const pattern = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|mailinator\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;;
        try {
            if (companyName == "") {
                toast.error("Please Enter Company Name")
            } else if (dateOfReg == "") {
                toast.error("Please Enter Date Of Register")
            } else if (!emailPattern.test(companyEmail)) {
                toast.error("Please Enter Valid Email")
            } else if (companyEmail == "") {
                toast.error("Please Enter Company Email")
            } else if (!pattern.test(companyEmail)) {
                toast.error("Please Enter a Company Email")
            } else if (companyPhone == "") {
                toast.error("Please Enter Company Phone")
            } else if (companyAddress == "") {
                toast.error("Please Enter Company Address")
            } else if (companyAddressImg == "") {
                toast.error("Please Upload Company Address Image")
            } else if (vatNumber == "") {
                toast.error("Please Enter Vat Number")
            } else if (vatImg == "") {
                toast.error("Please Upload Vat Image")
            } else if (itNumber == "") {
                toast.error("Please Enter IT Number")
            } else if (itImg == "") {
                toast.error("Please Upload IT Image")
            } else if (bankImg == "") {
                toast.error("Please Upload Bank Image")
            }
            else if (direcorImg?.length == 0) {
                toast.error("Please Upload Direcor Image")
            }
            else {
                setApiStatus(true)
                setApiCallStatus(true)
                var payload = {
                    CompanyName: companyName,
                    DateOfReg: dateOfReg,
                    CompanyEmail: companyEmail,
                    Phone: companyPhone,
                    Address: companyAddress,
                    Address_Proof: companyAddressImg,
                    VatNumber: vatNumber,
                    VatNumber_Proof: vatImg,
                    ITNumber: itNumber,
                    ITNumber_Proof: itImg,
                    Bank_Proof: bankImg,
                    Diretors: direcorImg
                }
                const { data } = await Axios.post('/users/createKyb', payload,
                    {
                        headers: {
                            Authorization: window.localStorage.getItem('Rikosta')
                        }
                    }
                )
                if (data?.success == true) {
                    setApiCallStatus(false)
                    toast.success(data?.message)
                    navigate('/dashboard')
                    setApiStatus(false)
                } else {
                    setApiCallStatus(false)
                    setApiStatus(false)
                    toast.error(data?.message)
                }
            }
        } catch (error) {
            setApiStatus(false)
            setApiCallStatus(false)
            console.log("🚀 ~ handleSave ~ error:", error)
        }
    }

    // const PDFViewer = ({ pdfUrl }) => {
    //     return <PdfViewer document={{ url: pdfUrl }} />;
    //   };

    return (
        <div className='kyb'>
            <Box sx={{ display: isLgUp ? 'flex' : 'block' }}>
                <Sidebar />
                {
                    usertype === 'organization' || subAdminDetails?.access?.kyb === true ?
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1,
                                marginTop: "80px",
                                // height: '100vh'
                            }}
                        >
                            <Grid container spacing={0} justifyContent={"center"}>
                                <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
                                    <h2 className="Head-Kyb">
                                        Know Your Business
                                    </h2>
                                    {
                                        statusKyc &&
                                        <div>
                                            <h2 style={{ textAlign: "center", backgroundColor: "wheat", padding: "1%" }}>
                                                Your KYB Verification is {statusKyc}  {statusKyc == "Rejected" && <Button onClick={() => { clearData() }} >Re Verify</Button>}
                                                {statusKyc == "Rejected" && <div>Reason: {resons}</div>}
                                            </h2>
                                        </div>
                                    }
                                    <section class="steps steps-area">
                                        <div class="container">
                                            {/* <!-- title-section --> */}
                                            <ul class="timeline">
                                                <li class="timeline-box wow fadeInLeft">
                                                    <div className="main-layer">
                                                        <i class="icon-layers"></i>
                                                        <p class="timeline-title">Company Name</p>
                                                        <TextField class="input" value={companyName} disabled={kycStatus} name="text" type="text" placeholder="Enter company name..." onChange={(e) => setCompanyName(e.target.value)} />
                                                        <span className='spans'>
                                                            <img width="80" height="80" src="https://img.icons8.com/dusk/80/client-company--v2.png" alt="client-company--v2" />
                                                        </span>
                                                    </div>

                                                </li>
                                                <li class="timeline-box wow fadeInRight">
                                                    <i class="icon-chat"></i>
                                                    <p class="timeline-title">Date of Registry</p>

                                                    <TextField placeholder="Search" value={dateOfReg} disabled={kycStatus} class="input" type="date" onChange={(e) => { setDateOfReg(e.target.value) }} />
                                                    <span className='spans'><img width="80" height="80" src="https://img.icons8.com/plasticine/80/overtime.png" alt="overtime" /></span>
                                                </li>
                                                <li class="timeline-box  wow fadeInLeft">
                                                    <i class="icon-tools"></i>
                                                    <p class="timeline-title">Company Email</p>
                                                    <TextField class="input" value={companyEmail} disabled={kycStatus} name="text" type="text" placeholder="Enter company email..." onChange={(e) => setCompanyEmail(e.target.value)} />
                                                    <span className='spans'><img width="80" height="80" src="https://img.icons8.com/color/80/gmail--v1.png" alt="gmail--v1" /></span>
                                                </li>
                                                <li class="timeline-box wow fadeInRight">
                                                    <i class="icon-trophy"></i>
                                                    <p class="timeline-title">Phone No:</p>
                                                    <TextField class="input" value={companyPhone} disabled={kycStatus} name="text" placeholder="Enter Number..." type="number" onChange={(e) => setCompanyPhone(e.target.value)} />

                                                    <span className='spans'>
                                                        <img width="80" height="80" src="https://img.icons8.com/doodle/80/ringing-phone.png" alt="ringing-phone" />
                                                    </span>
                                                </li>
                                                <li class="timeline-box wow fadeInLeft">
                                                    <div className="main-layer">
                                                        <i class="icon-layers"></i>
                                                        <p class="timeline-title">Company Address</p>
                                                        <TextField
                                                            id="outlined-multiline-static"
                                                            // label="Multiline"
                                                            multiline
                                                            rows={4}
                                                            disabled={kycStatus}
                                                            value={companyAddress}
                                                            // defaultValue="Default Value"
                                                            style={{ width: '300px' }}
                                                            className='input2'
                                                            placeholder="Enter address..."
                                                            onChange={(e) => setCompanyAddress(e.target.value)}
                                                        />
                                                        <span className='spans'>
                                                            <img width="80" height="80" src="https://img.icons8.com/officel/80/mailbox-with-letter.png" alt="mailbox-with-letter" />
                                                        </span>
                                                    </div>

                                                </li>
                                                <li class="timeline-box wow fadeInRight">
                                                    <i class="icon-trophy"></i>
                                                    <p class="timeline-title">Proof of Address(Reg Certificate):</p>
                                                    {/* /* From Uiverse.io by Ma7moud344 */}

                                                    {load == "address" ? <div class="loader"></div> :
                                                        <>

                                                            <div class="container2">
                                                                <div class="folder">
                                                                    <div class="front-side">
                                                                        <div class="tip"></div>
                                                                        <div class="cover"></div>
                                                                    </div>
                                                                    <div class="back-side cover"></div>
                                                                </div><label class="custom-file-upload">
                                                                    <input class="title" type="file" disabled={kycStatus} onChange={(e) => { handleImageUpload(e, "address") }} />
                                                                    {companyAddressImg ? 'Reupload file' : 'Choose a file'}
                                                                </label>
                                                            </div>
                                                            {console.log(companyAddressImg, forpdf, "companyAddressImg")}

                                                            {companyAddressImg && forpdf === true ?
                                                                <PDFViewer pdfUrl={companyAddressImg} /> :

                                                                companyAddressImg && forpdf === false ?
                                                                    <img src={companyAddressImg} alt={companyAddressImg} className='imgurl' /> :
                                                                    <></>
                                                            }

                                                            {/* <div className='pdfviewer'>
                                                            <PDFViewer pdfUrl={companyAddressImg} />
                                                            </div> */}

                                                            {/* <object data={companyAddressImg} type="application/pdf" width="100%" height="100%">
                                                                <p>Alternative text - include a link <a href={"http://africau.edu/images/default/sample.pdf"}>to the PDF!</a></p>
                                                            </object> */}
                                                            {/* {companyAddressImg && <img src={companyAddressImg} alt={companyAddressImg} className='imgurl' />} */}
                                                        </>
                                                    }

                                                    <span className='spans'>
                                                        <img width="80" height="80" src="https://img.icons8.com/color/80/checked-identification-documents.png" alt="checked-identification-documents" />
                                                    </span>
                                                </li>
                                                <li class="timeline-box wow fadeInLeft">
                                                    <div className="main-layer">
                                                        <i class="icon-layers"></i>
                                                        <p class="timeline-title">VAT Reg Number</p>
                                                        <TextField class="input" name="text" value={vatNumber} disabled={kycStatus} placeholder="Enter VAT Number..." type="number" onChange={(e) => setVatNumber(e.target.value)} />
                                                        <span className='spans'>
                                                            <img width="80" height="80" src="https://img.icons8.com/arcade/80/signing-a-document.png" alt="signing-a-document" />
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="timeline-box wow fadeInRight">
                                                    <i class="icon-trophy"></i>
                                                    <p class="timeline-title">Proof of VAT(Reg Number   ):</p>
                                                    {/* /* From Uiverse.io by Ma7moud344 */}
                                                    {load == "vat" ? <div class="loader"></div> :
                                                        <>
                                                            <div class="container2">
                                                                <div class="folder">
                                                                    <div class="front-side">
                                                                        <div class="tip"></div>
                                                                        <div class="cover"></div>
                                                                    </div>
                                                                    <div class="back-side cover"></div>
                                                                </div><label class="custom-file-upload">
                                                                    <input class="title" type="file" disabled={kycStatus} onChange={(e) => { handleImageUpload(e, "vat") }} />
                                                                    {vatImg ? 'Reupload file' : 'Choose a file'}
                                                                </label>
                                                            </div>

                                                            {vatImg && forpdf === true ?
                                                                <PDFViewer pdfUrl={vatImg} /> :

                                                                vatImg && forpdf === false ?
                                                                    <img src={vatImg} alt={vatImg} className='imgurl' /> :
                                                                    <></>
                                                            }

                                                            {/* {vatImg && <img src={vatImg} alt={vatImg} className='imgurl' />} */}
                                                        </>
                                                    }

                                                    <span className='spans'>
                                                        <img width="80" height="80" src="https://img.icons8.com/color/80/checked-identification-documents.png" alt="checked-identification-documents" />
                                                    </span>
                                                </li>
                                                <li class="timeline-box wow fadeInLeft">
                                                    <div className="main-layer">
                                                        <i class="icon-layers"></i>
                                                        <p class="timeline-title">Income Tax  Number</p>
                                                        <TextField class="input" name="text" value={itNumber} disabled={kycStatus} placeholder="Income Tax Number..." type="number" onChange={(e) => { setItNumber(e.target.value) }} />
                                                        <span className='spans'>
                                                            <img width="80" height="80" src="https://img.icons8.com/color/80/refund-2--v1.png" alt="refund-2--v1" />
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="timeline-box wow fadeInRight">
                                                    <i class="icon-trophy"></i>
                                                    <p class="timeline-title">Proof of IncomeTax</p>
                                                    {/* /* From Uiverse.io by Ma7moud344 */}
                                                    {load == "it" ? <div class="loader"></div> :
                                                        <>
                                                            <div class="container2">
                                                                <div class="folder">
                                                                    <div class="front-side">
                                                                        <div class="tip"></div>
                                                                        <div class="cover"></div>
                                                                    </div>
                                                                    <div class="back-side cover"></div>
                                                                </div><label class="custom-file-upload">
                                                                    <input class="title" type="file" disabled={kycStatus} onChange={(e) => { handleImageUpload(e, "it") }} />
                                                                    {itImg ? 'Reupload file' : 'Choose a file'}
                                                                </label>
                                                            </div>
                                                            {console.log(itImg, "itImg")
                                                            }
                                                            {itImg && forpdf === true ?
                                                                <PDFViewer pdfUrl={itImg} /> :

                                                                itImg && forpdf === false ?
                                                                    <img src={itImg} alt={itImg} className='imgurl' /> :
                                                                    <></>
                                                            }


                                                            {/* {itImg && <img src={itImg} alt={itImg} className='imgurl' />} */}
                                                        </>
                                                    }
                                                    <span className='spans'>
                                                        <img width="80" height="80" src="https://img.icons8.com/color/80/checked-identification-documents.png" alt="checked-identification-documents" />
                                                    </span>
                                                </li>
                                                <li class="timeline-box wow fadeInLeft">
                                                    <i class="icon-trophy"></i>
                                                    <p class="timeline-title">Company Bank Account123</p>
                                                    {/* /* From Uiverse.io by Ma7moud344 */}
                                                    {load == "bank" ? <div class="loader"></div> :
                                                        <>
                                                            <div class="container2">
                                                                <div class="folder">
                                                                    <div class="front-side">
                                                                        <div class="tip"></div>
                                                                        <div class="cover"></div>
                                                                    </div>
                                                                    <div class="back-side cover"></div>
                                                                </div><label class="custom-file-upload">
                                                                    <input class="title" type="file" disabled={kycStatus} onChange={(e) => { handleImageUpload(e, "bank") }} />
                                                                    {bankImg ? 'Reupload file' : 'Choose a file'}
                                                                </label>
                                                            </div>
                                                            {console.log(bankImg, "bankImg")}
                                                            {bankImg && forpdf === true ?
                                                                <PDFViewer pdfUrl={bankImg} /> :

                                                                bankImg && forpdf === false ?
                                                                    <img src={bankImg} alt={bankImg} className='imgurl' /> :
                                                                    <></>
                                                            }

                                                            {/* {bankImg && <img src={bankImg} alt={bankImg} className='imgurl' />} */}
                                                        </>
                                                    }
                                                    <span className='spans'>
                                                        <img width="80" height="80" src="https://img.icons8.com/dusk/80/bank-building.png" alt="bank-building" />
                                                    </span>
                                                </li>
                                                <li class="timeline-box wow fadeInRight">
                                                    <i class="icon-trophy"></i>
                                                    <p class="timeline-title">Proof of every (Director of company) </p>
                                                    {/* /* From Uiverse.io by Ma7moud344 */}
                                                    {load2 == "director" ? <div class="loader"></div> : imageUrls ?
                                                        <>
                                                            <div class="container2">
                                                                <div class="folder">
                                                                    <div class="front-side">
                                                                        <div class="tip"></div>
                                                                        <div class="cover"></div>
                                                                    </div>
                                                                    <div class="back-side cover"></div>
                                                                </div><label class="custom-file-upload">
                                                                    <input class="title" disabled={kycStatus} type="file" onChange={handleImageUpload2} />
                                                                    Choose a file
                                                                </label>
                                                            </div>
                                                            <ImageList sx={{
                                                                width: 500, display: direcorImg.length < 0 && 'none',
                                                                height: direcorImg < 0 ? 'none !important' : 300,
                                                                overflowY: 'scroll'
                                                            }} cols={1} gap={12}>
                                                                {direcorImg?.map((item, index) => (
                                                                    <ImageListItem key={item}>
                                                                        <DeleteIcon onClick={() => {
                                                                            if (kycStatus == false) {
                                                                                setDirecorImg((prevFruits) =>
                                                                                    prevFruits.filter((_, i) => i !== index) // Step 3: Filter out the item at the specified index
                                                                                );
                                                                                setImageUrls((prevFruits) =>
                                                                                    prevFruits.filter((_, i) => i !== index) // Step 3: Filter out the item at the specified index
                                                                                );
                                                                            }
                                                                        }} />
                                                                        <img
                                                                            srcSet={`${item}`}
                                                                            src={`${item}`}
                                                                            alt={item}
                                                                            loading="lazy"
                                                                            className='imgurl-list'
                                                                        />
                                                                    </ImageListItem>
                                                                ))}
                                                            </ImageList>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    <span className='spans'>
                                                        <img width="80" height="80" src="https://img.icons8.com/dusk/80/bank-building.png" alt="bank-building" />
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>

                                    </section>
                                    <div className="text-center save">
                                        {kycStatus == false &&
                                            < Button disabled={apiStauts} onClick={() => { handleSave() }}>
                                                <div class="svg-wrapper-1">
                                                    <div class="svg-wrapper">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            width="30"
                                                            height="30"
                                                            class="icon"
                                                        >
                                                            <path
                                                                d="M22,15.04C22,17.23 20.24,19 18.07,19H5.93C3.76,19 2,17.23 2,15.04C2,13.07 3.43,11.44 5.31,11.14C5.28,11 5.27,10.86 5.27,10.71C5.27,9.33 6.38,8.2 7.76,8.2C8.37,8.2 8.94,8.43 9.37,8.8C10.14,7.05 11.13,5.44 13.91,5.44C17.28,5.44 18.87,8.06 18.87,10.83C18.87,10.94 18.87,11.06 18.86,11.17C20.65,11.54 22,13.13 22,15.04Z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <span>Save</span>
                                            </Button>
                                        }
                                    </div>
                                    {
                                        kybDetails !== undefined && kybDetails?.Verify !== "Pending" && kycStatus !== false ?
                                            <div className='mt-20 verififed-det'>
                                                <h2 style={{ textAlign: "center", backgroundColor: "wheat", padding: "1%" }}>
                                                    Verification Details
                                                </h2>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        <div className="padding-10 mt-10 text-center">
                                                            Address Document
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                                                        <div className="padding-10 mt-10 text-center">
                                                            {
                                                                addrApprove === 'Approve' ?
                                                                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                                                                    :
                                                                    <CancelOutlinedIcon style={{ color: "red" }} />
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        {
                                                            addrApprove === 'Decline' &&
                                                            <div className="text-center padding-10 mt-10">
                                                                Reason:{AddressReason ? AddressReason : "No Reasons Found"}
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        <div className="text-center padding-10 mt-10">
                                                            VAT Document
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                                                        <div className='text-center padding-10 mt-10'>
                                                            {
                                                                vatApprove === 'Approve' ?
                                                                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                                                                    :
                                                                    <CancelOutlinedIcon style={{ color: "red" }} />
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        {
                                                            vatApprove === 'Decline' &&
                                                            <div className="text-center padding-10 mt-10">
                                                                Reason:{VatReason ? VatReason : "No Reasons Found"}
                                                            </div>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        <div className="text-center padding-10 mt-10">
                                                            Bank Document
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                                                        <div className=" text-center padding-10 mt-10">
                                                            {
                                                                bankApprove === 'Approve' ?
                                                                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                                                                    :
                                                                    <CancelOutlinedIcon style={{ color: "red" }} />
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        {
                                                            bankApprove === 'Decline' &&
                                                            <div className="text-center padding-10 mt-10">
                                                                Reason:{BankReason ? BankReason : "No Reasons Found"}
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>

                                                        <div className="text-center padding-10 mt-10">
                                                            Income Tax Document

                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                                                        <div className="text-center padding-10 mt-10">
                                                            {
                                                                incomeApprove === 'Approve' ?
                                                                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                                                                    :
                                                                    <CancelOutlinedIcon style={{ color: "red" }} />
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        {
                                                            incomeApprove === 'Decline' &&
                                                            <div className="text-center padding-10 mt-10">
                                                                Reason:{IncomeReason ? IncomeReason : "No Reasons Found"}
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        <div className="text-center padding-10 mt-10">
                                                            Director's Document

                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                                                        <div className="text-center padding-10 mt-10">
                                                            {
                                                                directorsApprove === 'Approve' ?
                                                                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                                                                    :
                                                                    <CancelOutlinedIcon style={{ color: "red" }} />
                                                            }
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        {
                                                            directorsApprove === 'Decline' &&
                                                            <div className="text-center padding-10 mt-10">
                                                                Reason:{DirectorsReason ? DirectorsReason : "No Reasons Found"}
                                                            </div>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        <div className="padding-10 mt-10 text-center">
                                                            Director's Document Id Verfication
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                                                        <div className="padding-10 mt-10 text-center">
                                                            {
                                                                id_verify ?
                                                                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                                                                    :
                                                                    <CancelOutlinedIcon style={{ color: "red" }} />
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div> : <></>
                                    }
                                </Grid>

                            </Grid>
                            <Backdrop
                                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backdropFilter: 'blur(10px)' })}
                                open={open}
                                onClick={handleClose}
                            >
                                <div class="card2">
                                    <div class="loader2">
                                        <p>Checking..</p>
                                        <div class="words">
                                            <span class="word">Company Name</span>
                                            <span class="word">Company Address</span>
                                            <span class="word">VAT Number</span>
                                            <span class="word">Income-Tax Number</span>
                                            <span class="word">Proof of Address</span>
                                        </div>
                                    </div>
                                </div>
                            </Backdrop>

                            <Backdrop
                                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backdropFilter: 'blur(5px)' })}
                                open={apiCallStatus}
                            >
                                <div style={{ height: "80vh", }} >
                                    <div class="loader2">
                                        <span class="l">A</span>
                                        <span class="o">P</span>
                                        <span class="a">Z</span>
                                        <span class="d">K</span>
                                        <span class="i">Y</span>
                                        <span class="n">C</span>
                                        <span class="g">.</span>
                                        <span class="d1">.</span>
                                        <span class="d2">.</span>
                                    </div>
                                </div>
                            </Backdrop>
                        </Box> :
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1,
                                marginTop: "80px",
                                // height: '100vh'
                            }}
                        >
                            You Dont Have Permission To View
                        </Box>
                }
            </Box>
        </div >
    )
}

export default Kyb
