import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import "./ClientLists.css";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Axios from '../../../Axios';
import Sidebar from "../SideBar/Sidebar";
import { Triangle } from 'react-loader-spinner'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import toast from 'react-hot-toast';

import Modal from '@mui/material/Modal';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { decryptData } from '../../../middleware';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// selector one data

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];


const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  '@media(max-width:767.98px)': {
    width: '90%',
  },
  height: '600px',
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  overflowY: 'scroll',
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};




export default function ClientLists() {
  // create popup open and close

  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

  const MobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [usertype, setUserType] = useState('')
  const token = localStorage.getItem('Rikosta')

  const subAdminDetails = JSON.parse(localStorage.getItem('subadmin'))


  useEffect(() => {
    if (token) {
      var user = window.localStorage.getItem('userType');
      const userType = decryptData(user)
      setUserType(userType)
    }
  }, [token])

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openimport, setOpenImport] = useState(false);

  const handleClickImport = () => {
    setOpenImport(true);
  };
  const handleCloseImport = () => {
    setOpenImport(false);
  };

  // pagination

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // popup selector 1

  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // selector 2 data

  const [age, setAge] = React.useState('');

  const handleSelectChange = (event) => {
    setAge(event.target.value);
  };

  //  select file

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleUpload = () => {
  //   if (selectedFile) {
  //     console.log('File Selected:', selectedFile.name);
  //     // You can add your upload logic here
  //   }
  // };

  // table data
  function createdData(
    title,
    name,
    description,
    entry,
    type,
    date,
    time,
    gmt
  ) {
    return { title, name, description, entry, type, date, time, gmt };
  }

  const List = [
    createdData('sdad', 'sdad', 'asdasd', 'Applicant ids', 'Blocklist', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
    createdData('asdsdas', 'asdsdas', 'asdasd', 'Key', 'Custom', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
    createdData('sds', 'sds', 'wwewe', 'Key', 'Custom', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
    createdData('Gr', 'gr', 'sddf', 'Key', 'Custom', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
  ];

  const [clientList, setClientList] = useState([]);
  const [clientLoading, setClientLoading] = useState(false);

  const paginatedClientList = clientList.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const getClientList = async () => {
    try {
      setClientLoading(true);
      const { data } = await Axios.get('/clientlist',
        {
          headers: {
            Authorization: window.localStorage.getItem('Rikosta')
          }
        }
      )
      if (data?.success) {
        setClientList(data?.result);
      }
    } catch (error) {
      if (error?.status == 499) {
        toast.error(error?.response?.data?.message)
        window.localStorage.removeItem('Rikosta');
        window.localStorage.removeItem('userType');
        setTimeout(() => {
          window.location.replace('/login')
        }, 1000);
      }
    } finally {
      setClientLoading(false);
    }
  }


  useEffect(() => {
    getClientList();
  }, []);

  const [openOrg, setOpenOrg] = useState(false);

  const handleOpenOrg = () => setOpenOrg(true);
  const handleCloseOrg = () => setOpenOrg(false);

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [selcDoc, setSelcDoc] = useState("")

  const itemData = [
    {
      img: selcDoc?.address_proof,
      title: 'Address Document: ',
      rows: 2,
      cols: 2,
      featured: true,
    },
    {
      img: selcDoc?.ID_Image_front,
      title: 'Id FrontSide Image:',

    },
    {
      img: selcDoc?.ID_Image_Back,
      title: 'Id BackSide Image:',
    },
    {
      img: selcDoc?.faceImage,
      title: 'Id BackSide Image:',
    },
  ];

  const [openOrgTab2, setOpenOrgTab2] = useState(false);
  const [zoom, setZoom] = useState(null)
  const handleOpenOrgTab2 = (val) => { setOpenOrgTab2(true); setZoom(val) }
  const handleCloseOrgTab2 = () => setOpenOrgTab2(false);

  return (
    <div>
      <Box sx={{ display: mdScreen ? 'flex' : 'block' }}>
        <Sidebar />
        <Box component="main" className="clientList-body" sx={{ flexGrow: 1, p: 3, marginTop: '80px' }}>
          {
            usertype === 'organization' || subAdminDetails?.access?.client_lists === true ?

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className='display-4'>
                    <div>
                      {/* <Button className="add-list-btn cmn-btn" onClick={handleClickOpen}><AddIcon />Create list</Button> */}
                      <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open} className='createList-popup'
                      >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                          Create list
                        </DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            // color: (theme) => theme.palette.grey[500],
                            color: "#212736 !important",
                          }}
                        >
                          <CloseIcon className='close-svg' />
                        </IconButton>
                        <DialogContent>
                          <div className='email-field'>
                            <TextField fullWidth id="fullWidth" placeholder='Title' />
                          </div>
                          <div className='email-field'>
                            <TextField fullWidth id="fullWidth" placeholder='Description' />
                          </div>

                          <div className="entry">
                            <label className='display-1'>Entry type</label>

                            <FormControl class="formControl-flex">
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group">
                                <FormControlLabel value="key" control={<Radio />} label="Key" checked />
                                <FormControlLabel value="ids" control={<Radio />} label="Applicant ids" />
                                <FormControlLabel value="email" control={<Radio />} label="Emails" />
                                <FormControlLabel value="device" control={<Radio />} label="Device Fingerprints" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </DialogContent>

                        <DialogActions>
                          {/* autoFocus onClick={handleClose} */}
                          <Button className='add-list-btn action-btn ' >
                            Create
                          </Button>
                        </DialogActions>
                      </BootstrapDialog>
                    </div>

                    <div>
                      {/* <Button className="import-btn cmn-btn" onClick={handleClickImport}><FileDownloadOutlinedIcon />Import list</Button> */}
                      <BootstrapDialog
                        onClose={handleCloseImport}
                        aria-labelledby="customized-dialog-title"
                        open={openimport} className='createList-popup'
                      >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                          Import list Items
                        </DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseImport}
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            // color: (theme) => theme.palette.grey[500],
                            color: "#212736 !important",
                          }}
                        >
                          <CloseIcon class="close-svg" />
                        </IconButton>
                        <DialogContent>
                          <div>
                            <FormControl sx={{ m: 0, width: '100%' }}>
                              {/* <InputLabel id="demo-multiple-checkbox-label">Select List</InputLabel> */}
                              <Select
                                className="select-item-list email-field"
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                placeholder='Select List'
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                              >
                                {names.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    <Checkbox checked={personName.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div class="import-popup">
                            <InputLabel id="demo-simple-select-label seperate">Seperator</InputLabel>
                            <FormControl sx={{ m: 0, width: 160 }}>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                placeholder='Age'
                                className='select-item-list email-field'
                                value={age}
                                onChange={handleSelectChange}
                              >
                                <MenuItem value={','}>comma</MenuItem>
                                <MenuItem value={'.'}>dot</MenuItem>
                                <MenuItem value={';'}>semicolon</MenuItem>
                                <MenuItem value={'@'}>@</MenuItem>
                                <MenuItem value={'#'}>Hash</MenuItem>
                                <MenuItem value={'&'}>
                                  ampersand</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <Box className='email-field' sx={{ display: 'flex', flexDirection: 'column', }}>
                            <input
                              accept="image/*"
                              style={{ display: 'none' }}
                              id="raised-button-file"
                              type="file"
                              onChange={handleFileChange}
                            />
                            <label htmlFor="raised-button-file" className='upload-label'>
                              <Button variant="contained" component="span" className='upload-btn'>
                                <FileUploadOutlinedIcon />
                                Select File
                              </Button>
                            </label>
                            {selectedFile && (
                              <Typography variant="body1" sx={{ mt: 2 }}>
                                Selected File: {selectedFile.name}
                              </Typography>
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              // onClick={handleUpload}
                              sx={{ mt: 2, display: 'none' }}
                              disabled={!selectedFile}
                            >
                              Upload
                            </Button>
                          </Box>
                          <Typography variant="h6" className='import-info' sx={{ mt: 2 }}>
                            Load your CSV file. Use line breaks to separate the lines. The maximum
                            number of rows is 10,000. If there is more than one separator, the remaining
                            columns will be ignored. Click Import to preview the uploaded data.
                          </Typography>
                        </DialogContent>

                        <DialogActions>
                          {/* autoFocus onClick={handleClose} */}
                          <Button className='add-list-btn action-btn ' >
                            Import
                          </Button>
                        </DialogActions>
                      </BootstrapDialog>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='item-list-table'>
                    <TableContainer >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Liveness verified</TableCell>
                            <TableCell align="left">Id verified</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Country</TableCell>
                            <TableCell align="left">ApiKey</TableCell>
                            <TableCell align="center">Created at</TableCell>
                            {
                              usertype === 'organization' || subAdminDetails?.access?.client_view === true ?
                                <TableCell></TableCell>
                                : <></>
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {clientLoading ? (<TableRow > <TableCell colSpan={7} style={{ textAlign: "center" }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                              <Triangle color="#009ff5" height={80} width={80} />
                            </div>
                          </TableCell>  </TableRow>) : paginatedClientList.length > 0 ?
                            (paginatedClientList?.map((List, index) => (
                              <TableRow key={index}>
                                <TableCell>{List?.Firstname + " " + List?.Lastname}</TableCell>
                                <TableCell>{List?.Liveness_verify == true ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <CancelOutlinedIcon style={{ color: "red" }} />}</TableCell>
                                <TableCell>{List?.Id_verify == true ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <CancelOutlinedIcon style={{ color: "red" }} />}</TableCell>
                                <TableCell>{List?.email}</TableCell>
                                <TableCell>{List?.Country}</TableCell>
                                <TableCell>{List?.apikey?.slice(0, 5)}...{List?.apikey?.slice(-5)}</TableCell>
                                <TableCell> {List?.createdAt ? List?.createdAt.split("T")[0] : "-"}
                                </TableCell>
                                {
                                  usertype === 'organization' || subAdminDetails?.access?.client_view === true ?
                                    <TableCell>
                                      <div className='action-btn-flex display-4'>
                                        <Button><ModeOutlinedIcon className='action-btn-bdr' onClick={() => { handleOpenOrg(); setSelcDoc(List) }} /></Button>
                                        {/* <Button><FileUploadOutlinedIcon className='action-btn-bdr' /></Button>
                                 <Button><DeleteOutlineTwoToneIcon className='action-btn-bdr' /></Button> */}
                                      </div>
                                    </TableCell> : <></>
                                }

                              </TableRow>
                            ))) : (<TableRow >
                              <TableCell colSpan={7} style={{ textAlign: "center", fontSize: "20px" }}> No Data Found</TableCell>
                            </TableRow>)
                          }

                        </TableBody>


                      </Table>
                    </TableContainer>
                    {/* <>
                  <div className='add-false-content'>
                    There are no items yet
                  </div>
                </> */}
                  </div>

                  <TablePagination
                    component="div"
                    count={clientList?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>


                <Modal
                  open={openOrg}
                  // onClose={handleCloseOrg}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style2}>
                    <div className="text-right">
                      <CloseIcon onClick={handleCloseOrg} className='cursor cls-ic' />
                    </div>
                    <div className={matches ? "kyc-submit-user-main" : "kyc-submit-user-main kyb-mob"}>
                      <div className='display-2 m-10' >
                        <strong>First Name: </strong>
                        <div> {selcDoc?.Firstname}</div>
                      </div>
                      <div className='display-2 m-10' >
                        <strong>Last Name: </strong>
                        <div> {selcDoc?.Lastname}</div>
                      </div>
                      <div className='display-2 m-10' >
                        <strong>Date Of Birth </strong>
                        <div> {selcDoc?.dob}</div>
                      </div>
                      <div className='display-2 m-10' >
                        <strong>Email: </strong>
                        <div> {selcDoc?.email}</div>
                      </div>
                      <div className='display-2 m-10' >
                        <strong>Phone Number: </strong>
                        <div> {selcDoc?.phone}</div>
                      </div>
                      {/* <div className='display-3 m-10' style={{ flexDirection: "column", gap: "10px" }}>
                                            <strong>Address Document: </strong>
                                            <div>
                                                <div >
                                                    <img src={selcDoc?.Address_Proof} width={200} height={200} />
                                                </div>
                                            </div>
                                        </div> */}
                      <div className='display-2 m-10' >
                        <strong>Address Line 1: </strong>
                        <div> {selcDoc?.address_line1}</div>
                      </div>
                      {/* <div className='display-3 m-10' style={{ flexDirection: "column", gap: "10px" }}>
                                            <strong>VAT Document: </strong>
                                            <div>
                                                <div >
                                                    <img src={selcDoc?.VatNumber_Proof} width={200} height={200} />
                                                </div>
                                            </div>
                                        </div> */}
                      <div className='display-2 m-10'>
                        <strong>Address Line 2: </strong>
                        <div> {selcDoc?.address_line2}</div>
                      </div>



                      <div className='display-2 m-10'>
                        <strong>City: </strong>
                        <div> {selcDoc?.city}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>State: </strong>
                        <div> {selcDoc?.stateOrProvince}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Country: </strong>
                        <div> {selcDoc?.Country}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Postalcode: </strong>
                        <div> {selcDoc?.postalcode}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Id Country: </strong>
                        <div> {selcDoc?.ID_country}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Id Type: </strong>
                        <div> {selcDoc?.ID_type}</div>
                      </div>

                      <div className='display-2 m-10'>
                        <strong>Id Number: </strong>
                        <div> {selcDoc?.ID_Number}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>ApiKey: </strong>
                        <div> {selcDoc?.apikey?.slice(0, 10)}....{selcDoc?.apikey?.slice(-10)}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Name Check: </strong>
                        <div> {selcDoc?.Name_Check == true ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <CancelOutlinedIcon style={{ color: "red" }} />}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Id Verify: </strong>
                        <div> {selcDoc?.Id_verify == true ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <CancelOutlinedIcon style={{ color: "red" }} />}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Liveness Verify: </strong>
                        <div> {selcDoc?.Liveness_verify == true ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <CancelOutlinedIcon style={{ color: "red" }} />}</div>
                      </div>
                      <div className='display-2 m-10'>
                        <strong>Liveness Photo Match With ID: </strong>
                        <div> {selcDoc?.Liveness_verify == true ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <CancelOutlinedIcon style={{ color: "red" }} />}</div>
                      </div>

                      {
                        selcDoc?.ID_type == "NationalId" &&
                        <>
                          {
                            selcDoc?.results?.verification_Result?.sources?.length > 0 && selcDoc?.results?.verification_Result?.sources?.map((item, index) => {
                              return (
                                <div className='display-2 m-10'>
                                  <strong>{item?.name}: </strong>
                                  <div> {selcDoc?.results?.verification_Result?.results?.length > 0 && selcDoc?.results?.verification_Result?.results[0]?.matchCount == 0 ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <CancelOutlinedIcon style={{ color: "red" }} />}</div>
                                </div>

                              )
                            })
                          }

                        </>
                      }
                      <div className='display-2 m-10'>
                        <strong>Confidence: </strong>
                        <div> {selcDoc?.Confidence ? selcDoc?.Confidence : 0}</div>
                      </div>

                      {/* <div className='display-3 m-10' style={{ flexDirection: "column", gap: "10px" }}>
                                            <strong>Income Tax Document: </strong>
                                            <div>
                                                <div >
                                                    <img src={selcDoc?.ITNumber_Proof} width={200} height={200} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='display-3 m-10' style={{ flexDirection: "column", gap: "10px" }}>
                                            <strong>Bank Document: </strong>
                                            <div>
                                                <div >
                                                    <img src={selcDoc?.Bank_Proof} width={200} height={200} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='display-3 m-10' style={{ flexDirection: "column", gap: "10px" }}>
                                            <strong>Director's Document: </strong>
                                            <div>
                                                <div >
                                                    <img src={selcDoc?.Diretors?.length > 0 && selcDoc?.Diretors[0]} width={200} height={200} />
                                                </div>
                                            </div>
                                        </div> */}

                      <ImageList sx={{ width: '100%', height: 450 }} className={!matches && 'imagelist'}>
                        <ImageListItem key="Subheader" cols={2}>
                          <ListSubheader className='m-10' component="div"> Documents</ListSubheader>
                        </ImageListItem>
                        {itemData?.map((item) => {
                          if (item?.img) {
                            return (
                              <ImageListItem key={item.img}>
                                <img
                                  // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                  src={`${item.img}?w=248&fit=crop&auto=format`}
                                  alt={item.title}
                                  loading="lazy"
                                  style={{ width: '100%', height: '200px' }}
                                />
                                <ImageListItemBar
                                  title={item.title}
                                  subtitle={item.author}
                                  actionIcon={
                                    <IconButton
                                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                      aria-label={`info about ${item.title}`}
                                    >
                                      <ZoomOutMapIcon onClick={(() => { handleOpenOrgTab2(item.img) })} />
                                    </IconButton>
                                  }
                                />
                              </ImageListItem>
                            )
                          }
                        }

                        )}
                      </ImageList>
                      <div>
                        {/* {
                      selcDoc?.Verify == "Pending" ?
                        <div className='display-2' style={{ gap: "10px" }}>
                          <Button className="view-btn" disabled={sts} variant='contained' onClick={() => { changeStatus("Approved") }} >Approve</Button>
                          <Button className="view-btn" disabled={sts} variant='contained' onClick={() => { handleOpenOrgTab(); }} >Reject</Button>
                        </div>
                        :
                        <div>
                          <div>Status: {selcDoc?.Verify == "Rejected" ? selcDoc?.reason : selcDoc?.Verify}</div>
                        </div>
                    } */}
                      </div>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openOrgTab2}
                  onClose={handleCloseOrgTab2}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style3}>
                    <div className="text-right">
                      <CloseIcon onClick={handleCloseOrgTab2} className='cursor cls-ic' />
                    </div>
                    <div className='text-center display-3 Zoomimg'>
                      <img src={zoom} alt="orgImg" />
                    </div>

                  </Box>
                </Modal>
              </Grid>
              :
              <Grid container spacing={2}>
                You Dont Have Permission To View
              </Grid>
          }
        </Box>
      </Box>
    </div>
  )
}
